<template>
  <section id="dashboard-prediccion">
    <!--
    <b-row>
      <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">

        <stat
  
          :variant="stat.variant"
          :icon="stat.icon"
          :name="stat.name"
          :value="stat.value"
          :unit="stat.unit"
        ></stat>
      </b-col>
    </b-row>
    -->

    <b-row>
      <b-col sm="12">
        <ag-grid-table
          ref="grid-historico-runs"
          :debug="true"
          :configUrl="historicoOptimizationsConfig"
          :dataUrl="historicoOptimizationsData"
          @gridReady="onGridReady"
          @getRowsLoaded="onGetRowsLoaded"
          rowSelection="multiple"
          :rowClassRules="rowClassRules"
          :pinnedTopRowData="pinnedTopRowData"
          @selectionChanged="onSelectionChanged"
          @actionReceived="actionReceived"
          :actions="actions"
          :helper="true"
        >
       
          <template v-slot:actions>
            <div>
              <b-button-toolbar
                aria-label="Toolbar with button groups and input groups"
                justify
              >
                <b-button-group style="margin-bottom: 1rem">
                  <b-button
                    size="sm"
                    :disabled="disableCompareRuns"
                    @click="compareRuns()"
                    variant="primary"
                    >{{ $t("Comparar") }}</b-button
                  >
                </b-button-group>
              </b-button-toolbar>
            </div>
          </template>
         
          
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
      
      <script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import Stat from '@/views/clients/base/components/Stat.vue'

import useApiServices from '@/services/useApiServices.js';

import HistoricoPlanificacionesActions from "./HistoricoPlanificacionesActions.vue";


import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Stat,

    BOverlay,
    BSpinner,




    historicoPlanificacionesActions: HistoricoPlanificacionesActions


  },

  methods: {

    debugChange(value)
    {

      console.log(value)

      let decoded = JSON.parse(value)





    },


    roundValue(value, decimales = 2)
    {
      let tmp = Math.pow(10, decimales)

      return Math.round(value * tmp) / tmp
    },


    actionReceived(action_name, data, params)
    {
      console.log("actionReceived", { action_name, data, params })
    },

    searchChange(value)
    {
      console.log(value)

      this.input_variables.forEach(metric =>
      {

        if (metric.name.match(new RegExp(value, 'i')))
        {
          metric.show = true
        } else
        {
          metric.show = false

        }



      })

    },


    compareRuns()
    {

      this.$router.push({ name: 'compare-simulations', query: { ids: this.selectedRows.map(run => run.id) } })
    },





    onGridReady(gridApi, gridColumnApi, params)
    {

      this.gridApi = gridApi

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })



    },

    onSelectionChanged(selectedRows)
    {

      this.selectedRows = selectedRows

      this.disableCompareRuns = (selectedRows.length < 2)
      this.disableRellenar = !(selectedRows.length == 1)

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params)
    {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

      this.tableLoaded = true;


    },


    accionDescargar(data)
    {
      console.log(data)

      if (data.data.output_data == null)
      {
        return
      }

      let optimization_id = data.data.id;

      let query = qs.stringify({ optimization_id: optimization_id })

      useApiServices.getDownloadFile(useApiServices.detalleOptimizacion.detalleOptimizacionDownloadExcel  + "?" + query)


    },

    accionVerDetalles(data)
    {

      console.log(data)

      if (data.data.output_data == null)
      {
        return
      }

      let optimization_id = data.data.id;



      this.$router.push({ name: 'optimization-details', query: { optimization_id: optimization_id } })

    },


    loadVariables()
    {
      this.overlayMessage = "Cargando variables..."
      this.showOverlay = true

      return useApiServices.modelsRead({ name: this.model_name })
        .then((response) =>
        {

          let input_variables = response.data.input_variables.map(variable =>
          {

            let min = Math.floor(variable.meta_data.minimo)
            let max = Math.ceil(variable.meta_data.maximo)

            let value = min + (max - min) / 2

            if (variable.meta_data.options)
            {
              return {
                name: variable.name,
                value: 0,
                data: variable.meta_data.options,
                show: false,
                disabledInput: true

              }
            }
            else
            {
              return {
                name: variable.name,
                value: value,
                min: min,
                max: max,
                marks: [min, value, max],
                interval: 0.01,
                show: false,
                disabledInput: false

              }

            }



          });

          this.input_variables = input_variables

          this.showOverlay = false

        })
        .catch((error) =>
        {

        });

    }


  },

  mounted()
  {

    axios.request({
      method: "get",
      url: useApiServices.historicoPrediccionesMezclas.historicoPrediccionesMezclasStats,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) =>
      {
        this.stats = response.data
      })




  },




  created()
  {

    this.loadVariables()
      .then(() =>
      {


      })



  },


  data()
  {

    let debug = (this.$route.query.debug == '1')


    return {

      model_name: "CMP-CV12-14-OPT",
      service_name: "CMP-CV12-14-OPT",


      actions: [
        { name: "Ver Detalles", icon: "DownloadCloudIcon", action: this.accionVerDetalles },
        { name: "Descargar", icon: "DownloadCloudIcon", action: this.accionDescargar },

      ],

      debug: debug,

      showOverlay: false,
      overlayMessage: "Cargando...",
      tableLoaded: false,



      rowClassRules: {

        'escenario-base': (params) =>
        {
          if (params.data == undefined)
          {
            return false;
          }

          return params.data.default === 1;
        }

      },

      gridApi: null,

      itemsData: [],
      pinnedTopRowData: [],

      historicoOptimizationsConfig: useApiServices.historicoOptimizationsAutoConfig,
      historicoOptimizationsData: useApiServices.historicoOptimizationsAutoData,

      disableCompareRuns: true,
      disableRellenar: true,
      selectedRows: [],

      stats: [],

      input_variables: [],







    };
  },
};
      </script>
      <style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
    
    
    <style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
    
    <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
      